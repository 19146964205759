<template>
	<div>
		<van-popup v-model="showPick" position="bottom">
			<van-picker show-toolbar :columns="columns" @confirm="onConfirm"
				@cancel="showPick = false"   :value-key="valueKey" @change='changeValue' />
		</van-popup>
	</div>
</template>

<script>
	import {
		Popup,
		Picker
	} from 'vant'
	
	export default {
		props:{
			columns:Array,
			valueKey:String,
		},
		components: {
			[Popup.name]: Popup,
			[Picker.name]: Picker,
		},
		data() {
			return {
				showPick:false,
			}
		},
		methods: {
			onConfirm(value){
				this.$emit('onConfirm',value)
				this.showPick = false
			},
			changeValue(value){
				this.$emit('changeValue',value)
			}
		}
	}
</script>

<style lang="scss">

</style>
