<template>
  <div class="pageTop">
    <div class="my-page">
      <div class="top">
        <p>欢迎注册</p>
        <div>在游戏学习中见证宝贝的智慧成长</div>
      </div>

      <div class="form">
        <van-form
          label-width="8.6em"
          ref="form"
          @submit="onSubmit"
          :model="form"
        >
          <van-field
            v-model="form.RealName"
            label="姓名"
            placeholder="姓名"
            :rules="[{ required: true, message: '请填写您的姓名' }]"
            required
          />
          <van-field
            type="number"
            v-model="form.CellPhone"
            label="手机号"
            placeholder="手机号"
            :rules="[
              { required: true, message: '请填写您的手机号' },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: '手机格式错误'
              }
            ]"
            required
          />

          <van-field
            readonly
            clickable
            v-model="form.ParentType"
            label="您的身份"
            placeholder="点击选择您的身份"
            @click="$refs.parentTypePick.showPick = true"
            :rules="[{ required: true, message: '请填写您的身份' }]"
            required
          />

          <van-field
            v-model="form.ChildrenName"
            label="宝贝姓名"
            placeholder="宝贝姓名"
            :rules="[
              { required: true, message: '请填写宝贝姓名' },
              {
                pattern: /^[\u4e00-\u9fa5]+$/,
                message: '宝贝姓名只能为汉字！'
              }
            ]"
            required
          />

          <van-field
            readonly
            v-model="form.SchoolName"
            label="所在园所"
            placeholder="所在园所"
            :rules="[{ required: true, message: '请选择所在园所' }]"
            required
            @click="$refs.schoolPick.showPick = true"
          />

          <van-field
            v-model="form.IDCard"
            label="宝贝身份证号"
            placeholder="宝贝身份证号"
            :rules="[
              { required: true, message: '请填写宝贝身份证号' },
              {
                pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                message: '身份证格式错误！'
              }
            ]"
            required
            @input="getChildDetails"
            name="IDCard"
          />

          <van-field
            readonly
            v-model="form.JoinSchoolDate"
            label="入园时间"
            placeholder="入园时间"
            @click="showPickerJoinSchoolDate = !form.isExist"
            :rules="[{ required: true, message: '请选择入园时间' }]"
            required
            :disabled="form.isExist"
          />

          <!-- <van-field
            readonly
            clickable
            v-model="form.Session"
            label="届次"
            placeholder="点击选择届次"
            @click="$refs.sessionPick.showPick = !form.isExist"
            :rules="[{ required: true, message: '请选择届次' }]"
            required
            :disabled="form.isExist"
          /> -->

          <van-field
            readonly
            clickable
            v-model="form.ClassName"
            label="班级"
            placeholder="点击选择班级"
            @click="$refs.classPick.showPick = !form.isExist"
            :rules="[{ required: true, message: '请选择班级' }]"
            required
            :disabled="form.isExist"
          />

          <van-field
            readonly
            clickable
            v-model="form.BloodType"
            label="血型"
            placeholder="点击选择血型"
            @click="$refs.bloodTypePick.showPick = !form.isExist"
            :disabled="form.isExist"
          />

          <van-field
            clickable
            v-model="form.FirstContactName"
            label="紧急联系人姓名"
            placeholder="紧急联系人姓名"
            :rules="[{ required: true, message: '请填写紧急联系人姓名' }]"
            required
            :disabled="form.isExist"
          />

          <van-field
            clickable
            v-model="form.FirstContactCellPhone"
            label="紧急联系人电话"
            placeholder="紧急联系人电话"
            :rules="[
              { required: true, message: '请填写紧急联系人电话' },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: '手机格式错误'
              }
            ]"
            required
            :disabled="form.isExist"
          />

          <van-field
            clickable
            v-model="form.FirstRelationship"
            label="紧急联系人与宝贝关系"
            placeholder="紧急联系人与宝贝关系"
            :rules="[{ required: true, message: '请填写紧急联系人与宝贝关系' }]"
            required
            :disabled="form.isExist"
          />
          <van-field
            clickable
            v-model="form.FatherName"
            label="父亲姓名"
            placeholder="父亲姓名"
            :disabled="form.isExist"
            required
            :rules="[{ required: true, message: '请填写父亲姓名' }]"
          />
          <van-field
            clickable
            v-model="form.FatherCellPhone"
            label="父亲手机号"
            placeholder="父亲手机号"
            :disabled="form.isExist"
            required
            :rules="[{ required: true, message: '请填写父亲手机号' }]"
          />
          <van-field
            clickable
            v-model="form.FatherIDCard"
            label="父亲身份证号"
            placeholder="父亲身份证号"
            :disabled="form.isExist"
          />
          <van-field
            clickable
            v-model="form.MatherName"
            label="母亲姓名"
            placeholder="母亲姓名"
            :disabled="form.isExist"
            required
            :rules="[{ required: true, message: '请填写母亲姓名' }]"
          />
          <van-field
            clickable
            v-model="form.MatherCellPhone"
            label="母亲手机号"
            placeholder="母亲手机号"
            :disabled="form.isExist"
            required
            :rules="[{ required: true, message: '请填写母亲手机号' }]"
          />
          <van-field
            clickable
            v-model="form.MatherIDCard"
            label="母亲身份证号"
            placeholder="母亲身份证号"
            :disabled="form.isExist"
          />
          <van-field
            clickable
            v-model="form.RegisteredResidence"
            label="户口所在地"
            placeholder="户口所在地"
            :disabled="form.isExist"
          />
          <van-field
            clickable
            v-model="form.Address"
            label="家庭住址"
            placeholder="家庭住址"
            :rules="[{ required: true, message: '请填写户口所在地' }]"
            required
            :disabled="form.isExist"
          />
          <van-field
            name="uploadList"
            label="宝贝照片"
            :rules="[{ required: true, message: '请上传照片' }]"
            required
            :disabled="form.isExist"
          >
            <template #input>
              <van-uploader
                v-model="form.uploadList"
                :preview-full-image="false"
                accept="*"
                :max-count="1"
                @click-preview="review"
                :before-read="beforeRead"
                :disabled="form.isExist"
                :deletable="deletable"
              />
            </template>
          </van-field>

          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              color="#47AFA7"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
    <!-- 入院时间 -->
    <van-popup v-model="showPickerJoinSchoolDate" position="bottom">
      <van-datetime-picker
        type="date"
        title="请填写入园时间"
        v-model="currentDate"
        @confirm="onConfirmJoinSchoolDate"
        :maxDate="maxDate"
        @cancel="showPickerJoinSchoolDate = false"
      />
    </van-popup>

    <!-- 身份 -->
    <pickSelect
      ref="parentTypePick"
      :columns="parentTypeList"
      @onConfirm="onConfirmParentType"
      valueKey="EnumName"
    >
    </pickSelect>

    <!-- 学校 -->
    <pickSelect
      ref="schoolPick"
      :columns="schoolList"
      @onConfirm="onConfirmSchool"
      valueKey="Name"
    ></pickSelect>

    <!-- 届次 -->
    <pickSelect
      ref="sessionPick"
      :columns="sessionList"
      @onConfirm="onConfirmSession"
    ></pickSelect>
    <!-- 班级 -->
    <pickSelect
      ref="classPick"
      :columns="classList"
      @onConfirm="onConfirmClass"
      valueKey="ClassName"
    ></pickSelect>
    <!-- 血型 -->
    <pickSelect
      ref="bloodTypePick"
      :columns="bloodTypeList"
      @onConfirm="onConfirmBloodType"
      valueKey="EnumName"
    >
    </pickSelect>
  </div>
</template>

<script>
import {
  Button,
  Field,
  Form,
  Popup,
  Picker,
  DatetimePicker,
  Uploader,
  Toast
} from "vant";

import pickSelect from "@/components/common/pickSelect/index.vue";

export default {
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,
    [Toast.name]: Toast,
    pickSelect
  },
  data() {
    return {
      form: {
        RealName: "",
        CellPhone: "",
        ParentType: "", //身份
        SchoolID: "", // 学校Id
        SchoolName: "", //学校
        ChildrenName: "",
        IDCard: "", //宝贝身份证
        JoinSchoolDate: "",
        Session: "", //届次
        ClassID: "", //班级id
        ClassName: "", //班级名成
        BloodType: "", //血型
        FirstContactName: "",
        FirstContactCellPhone: "",
        FirstRelationship: "",
        FatherName: "", //父亲
        FatherCellPhone: "",
        FatherIDCard: "", //
        MatherName: "", //
        MatherCellPhone: "", //
        MatherIDCard: "",
        RegisteredResidence: "",
        Address: "",
        uploadList: [], //照片
        HeadUrl: "", //
        OpenId: "",
        isExist: false, //是否存在已注册
        ChildrenID: "" //小孩id
      },
      maxDate: new Date(),
      showPickerJoinSchoolDate: false, //入园时间
      parentTypeList: [], //身份数据
      sessionList: [], //届次数据
      //schoolID: , //登录自动获取的id
      currentDate: new Date(),
      classList: [],
      bloodTypeList: [], //血型数据
      schoolList: [], //学校数据
      id: "", //宝贝id
      groupID: localStorage.getItem("groupID"),
      changeRoleInfo: JSON.parse(window.localStorage.getItem("wxuserInfo")),
      deletable: true
    };
  },
  mounted() {
    this._getParentTypeList(); // 身份选择数据
    this._getSeesiongList();
    //this._getClassList()
    this._getBloodTypeList();
    this._getScoolList();
  },
  methods: {
    //提交(表单校验通过时触发)
    onSubmit() {
      Toast.loading({
        message: "加载中...",
        duration: 0
      });
      //初次注册(宝贝是否已经注册过)
      if (!this.form.isExist) {
        this.form.HeadUrl = this.changeRoleInfo.headUrl;
        this.form.OpenId = this.changeRoleInfo.OpenID;
        this.$axios.post("/api/Parent/Register", this.form).then(res => {
          Toast.clear();
          if (res.code == 200) {
            this.$router.back(1);
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      } else {
        // 编辑
        this.$axios
          .post("/api/Parent/BindChildrenForApp", {
            RealName: this.form.RealName,
            CellPhone: this.form.CellPhone,
            ParentType: this.form.ParentType,
            HeadUrl: this.changeRoleInfo.headUrl,
            OpenId: this.changeRoleInfo.OpenID,
            ChildrenID: this.form.ChildrenID
          })
          .then(res => {
            Toast.clear();
            if (res.code == 200) {
              this.$router.back(1);
              this.$toast.success(res.msg);
            } else {
              this.$toast.fail(res.msg || "操作失败");
              return false;
            }
          });
      }
    },
    // 获取已注册宝贝数据
    getChildDetails() {
      this.$refs.form.validate("IDCard").then(err => {
        if (!err) {
          this.iptChange();
        }
      });
    },
    iptChange() {
      if (this.form.SchoolID && this.form.IDCard) {
        Toast.loading({
          message: "加载中...",
          duration: 0
        });
        const { data: res } = this.$axios
          .get("/api/Children/GetDetailForApp", {
            schoolID: this.form.SchoolID,
            IDCard: this.form.IDCard
          })
          .then(res => {
            Toast.clear();
            if (res.data.isExist) {
              // 可以抽出去优化(目前没时间)
              this.form.ChildrenName = res.data.ChildrenName;
              this.form.JoinSchoolDate = res.data.JoinSchoolDate;
              this.form.Session = res.data.Session;
              this.form.ClassName = res.data.ClassName;
              this.form.ClassID = res.data.ClassID;
              this.form.FatherCellPhone = res.data.FatherCellPhone;
              this.form.FatherIDCard = res.data.FatherIDCard;
              this.form.FatherName = res.data.FatherName;
              this.form.FirstContactCellPhone = res.data.FirstContactCellPhone;
              this.form.FirstContactName = res.data.FirstContactName;
              this.form.FirstRelationship = res.data.FirstRelationship;
              this.form.ChildrenID = res.data.ID; //宝贝id
              this.form.MatherCellPhone = res.data.MatherCellPhone;
              this.form.MatherIDCard = res.data.MatherIDCard;
              this.form.MatherName = res.data.MatherName;
              this.form.Address = res.data.Address;
              this.form.RegisteredResidence = res.data.RegisteredResidence;
              this.form.uploadList = res.data.uploadList;
              this.form.BloodTypeName = res.data.BloodTypeName;
              this.form.isExist = res.data.isExist;
              this.form.SchoolID = res.data.SchoolID;
              this.deletable = false; //宝贝回显数据,删除按钮去掉
            } else {
              this.form.isExist = res.data.isExist;
              // this.form.ChildrenName = ''
              // this.form.JoinSchoolDate = ''
              // this.form.Session = ''
              // this.form.ClassName = ''
              // this.form.ClassID = ''
              // this.form.FatherCellPhone = ''
              // this.form.FatherIDCard = ''
              // this.form.FatherName = ''
              // this.form.FirstContactCellPhone = ''
              // this.form.FirstContactName = ''
              // this.form.FirstRelationship = ''
              // this.form.ChildrenID = ''
              // this.form.MatherCellPhone = ''
              // this.form.MatherIDCard = ''
              // this.form.MatherName = ''
              // this.form.Address = ''
              // this.form.RegisteredResidence = ''
              // this.form.uploadList = []
              // this.form.BloodTypeName = ''
              // this.form.isExist = res.data.isExist
            }
          });
      }
    },
    _getChildrenInfo() {
      this.$axios
        .get("/api/Children/Get", {
          id: this.id
        })
        .then(res => {
          console.log(res);
          this.form = res.data;
        });
    },
    // 身份确认
    onConfirmParentType(value) {
      this.form.ParentType = value.EnumName;
      this.$refs.parentTypePick.showPick = false;
    },
    // 学校确认
    onConfirmSchool(value) {
      this.form.SchoolID = value.ID;
      this.form.SchoolName = value.Name;
      this.$refs.schoolPick.showPick = false;
      // 学校班级和班级关联
      this.schoolChange();
      // 根据身份证和所在学校代入数据
      this.getChildDetails();
    },
    // 学校班级和班级关联
    schoolChange() {
      this._getClassList();
    },
    // 届次确认
    onConfirmSession(value) {
      this.form.Session = value;
      this.$refs.sessionPick.showPick = false;
    },
    // 入园时间确认
    onConfirmJoinSchoolDate(value) {
      //this.form.JoinSchoolDate = value
      this.form.JoinSchoolDate = this.formatter(value);
      console.log(this.form.JoinSchoolDate);
      this.showPickerJoinSchoolDate = false;
    },
    // 班级确认
    onConfirmClass(value) {
      this.form.ClassID = value.ID;
      this.form.ClassName = value.ClassName;
      this.$refs.classPick.showPick = false;
    },
    // 血型确认
    onConfirmBloodType(value) {
      this.form.BloodType = value.EnumName;
      this.$refs.bloodTypePick.showPick = false;
    },
    // 预览
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    beforeRead(file) {
      if (this.form.SchoolID == null || this.form.SchoolID == "") {
        this.$toast.fail("请先选择宝贝所在学校");
        return;
      }
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2
          },
          base64Codes => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "上传中...",
              duration: 0
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            formData.append("SchoolID", this.form.SchoolID);
            this.$axios
              .upload("/api/File/UploadFileForRegister", formData)
              .then(res => {
                Toast.clear();
                if (res.code === 200) {
                  //console.log(res)
                  this.$toast.success(res.msg);
                  this.form.uploadList.push(res.data);
                } else {
                  this.$toast.fail(res.msg || "操作失败");
                  return false;
                }
              });
          }
        );
      } else {
        Toast.loading({
          message: "上传中...",
          duration: 0
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        formData.append("SchoolID", this.form.SchoolID);
        this.$axios
          .upload("/api/File/UploadFileForRegister", formData)
          .then(res => {
            Toast.clear();
            if (res.code === 200) {
              //console.log(res)
              this.$toast.success(res.msg);
              this.form.uploadList.push(res.data);
            } else {
              this.$toast.fail(res.msg || "操作失败");
              return false;
            }
          });
      }
    },
    // 时间格式函数
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    // 身份选择数据
    async _getParentTypeList() {
      const { data: res } = await this.$axios.get("/api/Base/GetEnum", {
        type: "ParentType"
      });
      this.parentTypeList = res.List;
    },
    // 学校选择数据
    async _getScoolList() {
      const { data: res } = await this.$axios.get(
        "/api/School/SubsidiaryList",
        {
          schoolID: this.groupID
        }
      );
      this.schoolList = res;
    },
    // 血型数据
    async _getBloodTypeList() {
      const { data: res } = await this.$axios.get("/api/Base/GetEnum", {
        type: "BloodType"
      });
      this.bloodTypeList = res.List;
    },
    //届次数据
    _getSeesiongList() {
      let date = new Date();
      let a = date.getFullYear();
      let b = a - 1;
      let c = a - 2;
      let d = a - 3;
      let newArray = [];
      newArray.push(a + "届");
      newArray.push(b + "届");
      newArray.push(c + "届");
      newArray.push(d + "届");
      this.sessionList = newArray;
    },
    // 班级数据
    async _getClassList() {
      const { data: res } = await this.$axios.get("/api/Class/ListForApp", {
        schoolID: this.form.SchoolID //根据选择的学校,带出相关班级
      });
      this.classList = res;
    }
  }
};
</script>

<style lang="less" scoped>
.pageTop {
  height: 100%;
  background: #ffffff;
  position: relative;
  overflow-y: scroll;

  .form {
    padding: 30px 10px 20px;
  }

  .top {
    color: #000000;
    padding-top: 30px;

    p {
      font-size: 20px;
      margin-bottom: 10px;
    }

    div {
      color: #999999;
      font-size: 13px;
    }
  }
}
</style>
